<script lang="ts" setup>
defineProps<{
	rankLeagueType: string;
	isWheelAvailable?: boolean;
}>();
const prepareImgUrl = useImage();
const { isSpecialWheel } = useSpecialWheelDecor();

const bgImage = `url(${prepareImgUrl(
	`/nuxt-img/activities-wheel/${isSpecialWheel.value ? "special-wheel-bg" : "wheel-bg"}.png`,
	{
		format: "avif",
		height: 175,
		width: 262
	}
)})`;
</script>
<template>
	<div :class="['image-holder', { 'is-animated': isWheelAvailable }]">
		<NuxtImg
			v-if="isSpecialWheel"
			class="water"
			src="/nuxt-img/activities-wheel/water.png"
			width="248"
			height="248"
			format="avif"
			alt="ocean"
			loading="lazy"
		/>
		<NuxtImg
			class="lines"
			src="/nuxt-img/activities-wheel/wheel-lines.png"
			width="282"
			height="210"
			format="avif"
			alt="wheel-lines"
			loading="lazy"
		/>
		<NuxtImg
			class="sectors"
			:src="`/nuxt-img/activities-wheel/${rankLeagueType}/sectors.png`"
			format="avif"
			width="184"
			height="184"
			alt="sectors"
			loading="lazy"
		/>
		<NuxtImg
			class="button"
			:src="`/nuxt-img/activities-wheel/${rankLeagueType}/button.png`"
			format="avif"
			width="78"
			height="78"
			alt="button"
			loading="lazy"
		/>

		<NuxtImg
			class="border"
			:src="`/nuxt-img/activities-wheel/${rankLeagueType}/border.png`"
			format="avif"
			width="212"
			height="212"
			alt="border"
			loading="lazy"
		/>
	</div>
</template>
<style lang="scss" scoped>
.image-holder {
	position: absolute;
	top: 0;
	right: 0;
	width: 190px;
	height: 170px;

	&.is-animated {
		.sectors {
			animation: animation-sectors 16.5s infinite linear;
		}
		.button {
			animation: animation-button 16.5s infinite linear;
		}
	}

	img {
		position: absolute;
	}

	&::before {
		content: "";
		position: absolute;
		background: v-bind(bgImage) center/cover no-repeat;
		left: 0;
		top: 0;
		height: 170px;
		width: 255px;
	}

	.water {
		display: block;
		top: 42px;
		right: 4px;
		z-index: 2;
		width: 124px;
		height: 124px;
	}

	.lines {
		top: 45px;
		right: -9px;
		z-index: 5;
		width: 141px;
		height: 105px;
	}

	.sectors {
		top: 54px;
		right: 20px;
		z-index: 2;
		width: 92px;
		height: 92px;
	}

	.button {
		top: 81px;
		right: 46px;
		z-index: 3;
		width: 39px;
		height: 39px;
	}

	.border {
		top: 51px;
		right: 13px;
		z-index: 2;
		width: 106px;
		height: 106px;
	}

	@include media-breakpoint-down(md) {
		width: 100%;
		top: -30px;
		transform: scale(0.65);

		&::before {
			left: 50%;
			transform: translateX(-50%);
		}

		.lines,
		.sectors,
		.button,
		.border {
			left: 0;
			right: 0;
			margin: 0 auto;
		}

		.water {
			left: -2px;
			right: 0;
			margin: 0 auto;
		}
	}
}

@keyframes animation-sectors {
	0%,
	100% {
		transform: rotate(0);
	}
	34% {
		transform: rotate(730deg);
	}
	39.3939%,
	99.9999% {
		transform: rotate(720deg);
	}
}
@keyframes animation-button {
	0%,
	10%,
	20%,
	30%,
	39.3939%,
	100% {
		transform: scale(0.8105);
	}
	5%,
	15%,
	25%,
	35% {
		transform: scale(1);
	}
}
</style>
